<template>
  <div class="bill-page">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview title="Danh sách điện thoại bị hạn chế">
      <template v-slot:toolbar>
        <div class="ml-3" v-if="checkPermission('CUSTOMER_BLACK_LIST_CREATE')">
          <router-link :to="'/customer-black-list/create'">
            <b-button variant="primary" size="sm" class="font-weight-bolder">
              <i style="font-size: 1rem" class="flaticon2-add-1"></i> Tạo mới
            </b-button>
          </router-link>
        </div>
      </template>
      <template v-slot:preview>
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row class="mb-5">
              <b-col class="pl-0" md="3">
                <b-input v-model="id" placeholder="Nhập ID" size="sm"></b-input>
              </b-col>
              <b-col class="pl-0" md="3">
                <b-input
                  v-model="search"
                  placeholder="Nhập số điện thoại khách hàng"
                  size="sm"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1" style="padding-left: 0">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="fetchBlackList()"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </div>
        <div class="list-bill">
          <table class="table table-bordered-list table-hover col-md-12">
            <thead>
              <tr>
                <th class="text-center tdCode">ID</th>
                <th class="text-center tdCustomer">Khách hàng</th>
                <th class="text-center tdProductName">Số điện thoại</th>
                <th class="text-center tdNote">Ghi chú</th>
                <th class="text-center tdCreateBy">Người thực hiện</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template>
                <tr v-for="(item, i) in blackList" :key="i">
                  <td class="tdTextAlign tdCode text-center">
                    <span>{{ item.id }}</span>
                  </td>
                  <td class="tdTextAlign tdCode text-center">
                    <p
                      class="orderCode"
                      @click="editUser(item.customer)"
                      style="
                        margin-bottom: 0;
                        cursor: pointer;
                        font-weight: 550;
                      "
                    >
                      {{ item.customer.fullName }}
                    </p>
                  </td>
                  <td class="tdTextAlign tdCode">
                    <template>
                      <div
                        v-for="(customerPhone, index) in item.customer
                          .CustomerPhones"
                        :key="index"
                      >
                        <span>{{ customerPhone.phoneNumber }}</span>
                      </div>
                    </template>
                  </td>
                  <td
                    class="tdTextAlign tdNote wrapword"
                    @mouseover="hoverPrice = true"
                    @mouseleave="hoverPrice = false"
                  >
                    <p
                      v-if="item.description"
                      :title="item.description"
                      v-b-tooltip.hover
                    >
                      {{ truncateText(item.description) }}
                    </p>
                  </td>
                  <td class="tdTextAlign tdCode text-center">
                    <p
                      v-if="item.userCreate"
                      class="orderCode"
                      style="
                        margin-bottom: 0;
                        cursor: pointer;
                        font-weight: 550;
                      "
                    >
                      {{ item.userCreate.employee.fullName }}
                    </p>
                    <p
                      v-if="item.userCreate"
                      class="orderCode"
                      style="margin-bottom: 0; cursor: pointer"
                    >
                      {{ item.userCreate.employee.code }}
                    </p>
                  </td>
                  <td
                    class="tdTextAlign tdCode"
                    v-if="checkPermission('CUSTOMER_BLACK_LIST_UPDATE')"
                  >
                    <div class="d-flex justify-content-center">
                      <b-dropdown size="sm" id="dropdown-left" no-caret right>
                        <template slot="button-content">
                          <i class="flaticon2-settings function-btn p-0"></i>
                        </template>
                        <b-dropdown-item @click="editItem(item)">
                          <span style="color: #3f4254; font-size: 12px">
                            <i style="font-size: 1rem" class="flaticon-eye"></i>
                            &nbsp; Chi tiết
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="showDeleteAlert(item)">
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem; color: #d33"
                              class="flaticon2-rubbish-bin-delete-button"
                            ></i>
                            &nbsp; Xóa
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <vcl-table
            v-if="onLoading"
            :speed="5"
            :animate="true"
            :columns="11"
          ></vcl-table>
        </div>
        <b-row>
          <b-col>
            <BPaginationNavCustom
              :number-of-pages="totalPages"
              :current-page="page"
              :total-page="totalPages"
              @page-change="fetchBlackList"
            >
            </BPaginationNavCustom>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import KTCodePreview from '@/view/content/CodePreview.vue';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { DELETE_TYPE } from '../../../utils/enum';
import Swal from 'sweetalert2';
import { makeToastSuccess, makeToastFaile } from '@/utils/common';
import { checkPermission } from '@/utils/saveDataToLocal';

export default {
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    BPaginationNavCustom,
  },
  data() {
    return {
      id: null,
      totalPages: 1,
      totalItems: 0,
      blackList: [],
      page: 1,
      limit: 10,
      search: '',
      onLoading: true,
    };
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),

    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
  created() {
    this.fetchBlackList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách điện thoại bị hạn chế' },
    ]);
  },
  methods: {
    checkPermission,
    editItem: function (item) {
      const { href } = this.$router.resolve({
        name: 'update',
        query: { id: item.id },
      });
      window.open(href, '_self');
    },
    fetchBlackList() {
      const params = {
        page: this.page,
        limit: this.limit,
      };
      if (this.search) {
        params['search'] = this.search;
      }
      if (this.id) {
        params['id'] = this.id;
      }
      ApiService.query('customer/black-list', { params })
        .then((response) => {
          const { data } = response.data;
          this.blackList = data.dataset;
          this.totalPages = data.totalPage;
          this.totalItems = data.totalRow;
          this.onLoading = false;
        })
        .catch((err) => {
          this.onLoading = false;
          makeToastFaile(`Lỗi: ${err}`);
        });
    },
    editUser: function (item) {
      const { href } = this.$router.resolve({
        name: 'update-customer',
        query: { id: item.id, phoneNo: item.phoneNo },
      });
      window.open(href, '_blank');
    },
    truncateText(text) {
      const MAX_STRING = 200;
      if (!text || !text.length) {
        return '';
      }
      return text.length < MAX_STRING
        ? text
        : text.substring(0, MAX_STRING) + '...';
    },
    deleteItem: function (item) {
      let data = {
        id: item.id,
        customerId: item.customerId,
        deleted: DELETE_TYPE.DELETED,
      };
      ApiService.put('customer/black-list', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.fetchBlackList();
          } else {
            makeToastFaile(message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.data.message ?? response.$error);
        });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa khách hàng này!',
        text: 'Bạn có chắc muốn xóa khách hàng này khỏi danh sách không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
  },
};
</script>
<style scoped>
.table-bordered-list th {
  border: 0.5px solid #d2d4d6 !important;
  background-color: #e0e0e0;
}

.table-bordered-list td {
  border: 0.5px solid #f3f3f3;
  /* border: 0.5px solid #fdfbfb; */
  /* border: 0.5px solid #d5d5d5; */
  border-bottom: 0.5px solid #adadad;
}
.tdTextAlign {
  vertical-align: middle;
}
.tdNote {
  width: 20%;
  overflow-wrap: break-word;
  word-break: break-all;
}
.tdCreateBy {
  width: 10%;
}
</style>
